<template>
    <v-sheet elevation="2" width="100%">
        <v-toolbar short dense color="primary" dark flat>
            

            <v-toolbar-title>Plan de alta</v-toolbar-title>
        </v-toolbar>

        <v-alert type="warning" class="mt-3 mx-5" v-if="da.id === null">
            Esta sección sólo deberá ser complementada al egreso del usuario
        </v-alert>

        <v-form class="px-5 pt-5">

            <v-row class="px-1">
                <v-col xs="12" sm="6" md="6" lg="4" xl="4" cols="12" v-for="itm in campos" :key="itm.id">
                    <v-textarea
                        outlined
                        filled
                        dense
                        :readonly="da.id !== null"
                        :label="itm.label"
                        v-model='da[itm.modelo]'
                    ></v-textarea>
                </v-col>
                

                <v-col cols="12">
                    <v-text-field
                        outlined
                        filled
                        dense
                        :readonly="da.id !== null"
                        label="Persona que recibe recomendaciones"
                        v-model="da.responsable"
                    ></v-text-field>
                </v-col>
                
                <v-col cols="12">
                    <div class="float-right">
                            <v-btn
                            v-if="da.id === null"
                            :disabled="planLoading"
                            class="ml-1 white--text"
                            color="green"
                            :loading="loadingGuarda"
                            rounded
                            @click="guarda"
                            
                        >
                            Guarda
                        </v-btn>
                    
                        <v-btn
                            v-if="(tieneRol('ROLE_ENFERMERIA_ADMIN')||tieneRol('ROLE_ADMIN'))&&da.id !== null"
                            class="ml-1 white--text"
                            color="red"
                            rounded
                            text
                            @click="borraEntrada(da.id)"
                        >
                            
                            <v-icon left>
                                mdi-eraser
                            </v-icon>
                            Borra
                        </v-btn>
                    </div>
                </v-col>

            </v-row>
        </v-form>
    </v-sheet>
</template>

<script>
export default {
    mounted(){
        this.carga()
    },
    props:{
        internamiento: Number
    },
    data:()=>({
        loadingGuarda: false,
        planLoading:false,

        campos:[
            {id:1, modelo:'sintomas', label:'Signos y síntomas de alerta'},
            {id:2, modelo:'medicamentos', label:'Medicamentos y reacciones'},
            {id:3, modelo:'citas', label:'Control de citas'},
            {id:4, modelo:'dieta', label:'Tipo de dieta'},
            {id:5, modelo:'procedimientos', label:'Manejo de procedimientos'},
            {id:6, modelo:'higiene', label:'Hábitos higiénicos'},
            {id:7, modelo:'ejercicio', label:'Ejercicio/recreación'},
            {id:8, modelo:'valores', label:'Ejercer sus valores y creencias'},
            {id:9, modelo:'otros', label:'Otros'},
        ],
        da:{
            id:null,
            sintomas:'',
            medicamentos: '',
            citas: '',
            dieta: '',
            procedimientos: '',
            higiene: '',
            ejercicio: '',
            valores: '',
            otros: '',
            responsable: '',
        },
    }),
    methods:{

        tieneRol(rol){
            let contiene = this.$store.state.security.roles.includes(rol)
            return (contiene)
        },

        async borra2(id,motivo){
            try{
                let borra = await this.$http({
                    method:'DELETE',
                    url:'/enfermeria/borraAlta',
                    data:{id:id, motivo:motivo}
                })
                if (borra.data.estado === true){
                    this.carga()
                } else {
                    console.log(borra.data)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede eliminar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        borraEntrada(id){
            //let motivo 
            this.$swal.fire({
                title: 'Borrar esta entrada',
                text: 'Motivo para borrar esta entrada',
                input: 'text',
                //inputValue:motivo,
                confirmButtonText: 'Borrar registro',
                showLoaderOnConformation: true,
                inputValidator:  (value) => {
                    if (value) {

                        this.$swal.fire({
                            title:'¿Desea borrar?',
                            text:'Esta acclón no se puede deshacer',
                            icon: 'warning',
                            showCancelButton: true
                        }).then((result)=>{
                            if (result.value){
                                this.borra2(id,value)
                            }
                        })
                    } else {
                        return 'Indique el motivo para esta eliminación'
                    }
                }
            })
        },

        async carga(){
            this.planLoading = true
            let params = {
                internamiento: this.internamiento,
            }

            try{
                let resultados = await this.$http({
                    url:'/enfermeria/listPlanAlta',
                    method:'get',
                    params: params
                })
                this.planLoading = false
                if (resultados.data.estado == true){

                    //console.log(resultados.data.root)
                    this.da = resultados.data.root
                    this.agregaLoading = false
                    // carga los resultados

                } else {
                    //console.log(resultados.data.root)
                    this.da = {
                        id:null,
                        sintomas:'',
                        medicamentos: '',
                        citas: '',
                        dieta: '',
                        procedimientos: '',
                        higiene: '',
                        ejercicio: '',
                        valores: '',
                        otros: '',
                        responsable: '',
                    },

                    this.agregaLoading = false
                }

            }catch(err){
                this.planLoading = false
                this.$store.dispatch('security/revisaError',err)
            }
            
        },

        async guarda(){
            this.loadingGuarda=true
            let params = {...this.da}
            params.internamiento = this.internamiento

            try{
                let resultados = await this.$http({
                    url:'/enfermeria/guardaPlanAlta',
                    method:'POST',
                    data: params
                })

                if (resultados.data.estado == true){
                    this.loadingGuarda = false
                    this.carga()

                } else {
                    console.log(resultados.data.root)
                    this.loadingGuarda = false
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                    
                }

            }catch(err){
                this.loadingGuarda = false
                this.$store.dispatch('security/revisaError',err)
            }

        }
    },
}
</script>

<style>

</style>